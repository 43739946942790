import React, { useEffect, useState } from 'react';
import "../css/services.css";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const servicesData = [
  {
    id: 1,
    key: 'physiotherapy',
    imageUrl: "assets/physiotherapy art.png",
  },
  {
    id: 2,
    key: 'laserHairRemoval',
    imageUrl: "assets/laser art.png",
  },
  {
    id: 3,
    key: 'deepCleaning',
    imageUrl: "assets/pastrim art.png",
  },
  {
    id: 4,
    key: 'hydrafacial',
    imageUrl: "assets/hidrofacial art.png",
  },
  {
    id: 5,
    key: 'antiAging',
    imageUrl: "assets/antiage art.png",
  },
  {
    id: 6,
    key: 'manualLifting',
    imageUrl: "assets/lifting art.png",
  },
  {
    id: 7,
    key: 'celluliteTreatment',
    imageUrl: "assets/anticelulit art.png",
  },
  {
    id: 8,
    key: 'detoxTreatment',
    imageUrl: "assets/detox art.png",
  },
  {
    id: 9,
    key: 'sportsTechniques',
    imageUrl: "assets/sportive art.png",
  },
  {
    id: 10,
    key: 'pressotherapy',
    imageUrl: "assets/presoterapi art.png",
  },
  {
    id: 11,
    key: 'lymphoDrainage',
    imageUrl: "assets/limfodrenazh.webp",
  },
];

const Services = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Track scroll position to apply parallax effect
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>Meliora Medical - {t('services.headerTitle')}</title>
      </Helmet>

      {/* Hero Section */}
      <div
        className="hero-container header-services"
        style={{ backgroundPositionY: `${scrollPosition * 0}px` }} // Apply parallax effect
      >
        <div className="hero-content">
          {/* Text Section */}
          <h1 className="hero-title">{t('services.headerTitle')}</h1>
          {/* Contact Button */}
          <a href="tel:+355676869685" className="btn btn-primary">{t('Telefono')}</a>
        </div>
      </div>


      <div className="title-container">
        <h1 className='services-title' id='intro'>{t('services.introTitle')}</h1>
        <p className='center-p-normal container margin-bottom'>{t('services.introDescription')}</p>
      </div>

      <section className="container services-section">
        <div className="services-blobs">
          <a href="#physiotherapy-section" className="service-blob">
            <img src={`${process.env.PUBLIC_URL}/assets/Physiotherapy Blob.png`} alt={t('services.physiotherapy.title')} />
            <p>{t('services.physiotherapy.title')}</p>
          </a>
          <a href="#laserHairRemoval-section" className="service-blob">
            <img src={`${process.env.PUBLIC_URL}/assets/Epilim Blob.png`} alt={t('services.laserHairRemoval.title')} />
            <p>{t('services.laserHairRemoval.title')}</p>
          </a>
          <a href="#faceTreatment-section" className="service-blob">
            <img src={`${process.env.PUBLIC_URL}/assets/Ftyre Blob.png`} alt={t('service-title.sectionTitles.2')} />
            <p>{t('service-title.sectionTitles.2')}</p>
          </a>
          <a href="#bodyTreatment-section" className="service-blob">
            <img src={`${process.env.PUBLIC_URL}/assets/Trupi Blob.png`} alt={t('service-title.sectionTitles.6')} />
            <p>{t('service-title.sectionTitles.6')}</p>
          </a>
        </div>
      </section>

      <div className="container services-page">
        {servicesData.map((service, index) => (
          <div key={service.id}>
            {/* Conditional rendering for specific section titles */}
            {(index === 0 || index === 1 || index === 2 || index === 6) && (
              <h1 className='services-title' id={index === 2 ? 'faceTreatment-section' : index === 6 ? 'bodyTreatment-section' : `${service.key}-section`}>
                {t(`service-title.sectionTitles.${index}`)}
              </h1>
            )}
            <ServiceSection
              title={t(`services.${service.key}.title`)}
              description={t(`services.${service.key}.description`)}
              buttonText={t('services.buttonText')}
              imageUrl={service.imageUrl}
              reverse={index % 2 === 1}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const ServiceSection = ({ title, description, buttonText, imageUrl, reverse }) => {
  return (
    <div className={`service-section ${reverse ? "reverse" : ""}`}>
      <div className="service-image">
        <img src={imageUrl} alt={title} />
      </div>
      <div className="service-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <a href='/offer' className="btn btn-primary">{buttonText}</a>
      </div>
    </div>
  );
};

export default Services;
