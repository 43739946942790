import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './../css/navbar.css';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const navHeight = document.querySelector('.nav-wrapper').offsetHeight;
    setIsScrolled(scrollTop > navHeight);
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false); // Close the mobile menu
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of page
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      // Force a re-render when the language changes
      setIsMobileMenuOpen(false);
    };

    i18n.on('languageChanged', handleLanguageChange);

    window.addEventListener('scroll', handleScroll);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [i18n]);

  return (
    <div className={`page-wrapper ${isScrolled ? 'scrolled' : ''}`}>
      <div className={`nav-wrapper ${isScrolled ? 'scrolled' : ''}`}>
        <nav className="navbar">
          <Link to="/" onClick={handleLinkClick}>
            <img src="/assets/Logo.png" alt={t('Company Logo')} className={isScrolled ? 'scrolled' : ''} />
          </Link>
          <div
            className={`menu-toggle ${isMobileMenuOpen ? 'is-active' : ''} ${isScrolled ? 'scrolled' : ''}`}
            id="mobile-menu"
            onClick={handleMenuToggle}
          >
            <span className={`bar ${isScrolled ? 'scrolled' : ''}`}></span>
            <span className={`bar ${isScrolled ? 'scrolled' : ''}`}></span>
            <span className={`bar ${isScrolled ? 'scrolled' : ''}`}></span>
          </div>
          <ul className={`nav no-search ${isMobileMenuOpen ? 'mobile-nav' : ''} ${isScrolled ? 'scrolled' : ''}`}>
            <li className="nav-item">
              <Link to="/" className={isScrolled ? 'scrolled' : ''} onClick={handleLinkClick}>
                {t('Kryesore')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className={isScrolled ? 'scrolled' : ''} onClick={handleLinkClick}>
                {t('Rreth Nesh')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className={isScrolled ? 'scrolled' : ''} onClick={handleLinkClick}>
                {t('Shërbimet')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/offer" className={isScrolled ? 'scrolled' : ''} onClick={handleLinkClick}>
                {t('Oferta')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className={isScrolled ? 'scrolled' : ''} onClick={handleLinkClick}>
                {t('Kontakt')}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
