import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../components/ContactForm';
import '../css/contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Track scroll position to apply parallax effect
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Meliora Medical - {t('contact.headerTitle')}</title>
      </Helmet>
 {/* Hero Section */}
 <div
        className="hero-container header-contact"
        style={{ backgroundPositionY: `${scrollPosition * 0}px` }} // Apply parallax effect
      >
        <div className="hero-content">
          {/* Text Section */}
          <h1 className="hero-title">{t('contact.headerTitle')}</h1>
          {/* Contact Button */}
          <a href="tel:+355676869685" className="btn btn-primary">{t('Telefono')}</a>
        </div>
      </div>

      <div className="contact-overview-title">
        <h1>{t('contact.contactFormTitle')}</h1>
      </div>
      <ContactForm />
      <div className="contact-overview-title">
        <h1>{t('contact.locationTitle')}</h1>
      </div>
      <section className="map-section container">
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.7844253669314!2d19.8048132760311!3d41.313553100571724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350312e2255f883%3A0x9e3e7af27e2208dd!2sMeliora%20Medical%20Clinic!5e0!3m2!1sen!2sbe!4v1723136094270!5m2!1sen!2sbe"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title={t('contact.mapTitle')}
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;
