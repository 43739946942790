// src/components/PrivacyPolicy.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import './../css/privacypolicy.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  // Fetch arrays with returnObjects: true
  const informationCollected = t('privacyPolicy.sections.informationCollected.paragraphs', { returnObjects: true });
  const informationUse = t('privacyPolicy.sections.informationUse.paragraphs', { returnObjects: true });
  const informationSharing = t('privacyPolicy.sections.informationSharing.paragraphs', { returnObjects: true });
  const cookiesParagraphs = t('privacyPolicy.sections.cookies.paragraphs', { returnObjects: true });
  const contactList = t('privacyPolicy.sections.contact.list', { returnObjects: true });

  return (
    <div className="privacy-policy-container container">
      <h1 className='center-title'>{t('privacyPolicy.title')}</h1>

      <section>
        <h2>{t('privacyPolicy.sections.introduction.heading')}</h2>
        <p>
          {t('privacyPolicy.sections.introduction.paragraph')}
        </p>
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.informationCollected.heading')}</h2>
        <p>
          {informationCollected[0]}
        </p>
        <ul>
          {informationCollected.slice(1).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.informationUse.heading')}</h2>
        <p>
          {informationUse[0]}
        </p>
        <ul>
          {informationUse.slice(1).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.informationSharing.heading')}</h2>
        <p>
          {informationSharing[0]}
        </p>
        <ul>
          {informationSharing.slice(1).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.cookies.heading')}</h2>
        {cookiesParagraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.dataSecurity.heading')}</h2>
        <p>
          {t('privacyPolicy.sections.dataSecurity.paragraph')}
        </p>
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.userRights.heading')}</h2>
        <p>
          {t('privacyPolicy.sections.userRights.paragraph')}
        </p>
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.policyChanges.heading')}</h2>
        <p>
          {t('privacyPolicy.sections.policyChanges.paragraph')}
        </p>
      </section>

      <section>
        <h2>{t('privacyPolicy.sections.contact.heading')}</h2>
        <p>
          {t('privacyPolicy.sections.contact.paragraph')}
        </p>
        <ul>
          {contactList.map((item, index) => (
            <li key={index}>
              <b>{item.label}</b> <a href={`mailto:${item.value}`}>{item.value}</a>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
