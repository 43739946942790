import React, { useEffect, useState } from 'react';

import 'animate.css'; // Ensure you have this package installed: npm install animate.css
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


const Home = () => {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Track scroll position to apply parallax effect
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const types = [
    {
      imgSrc: '/assets/physiotherapy.png',
      title: t('types.fizioterapi'),
      description: t('types.fizioterapiDesc'),
    },
    {
      imgSrc: '/assets/Laser.png',
      title: t('types.epilimMeLazer'),
      description: t('types.epilimMeLazerDesc'),
    },
    {
      imgSrc: '/assets/face.png',
      title: t('types.trajtimeFytyre'),
      description: t('types.trajtimeFytyreDesc'),
    },
    {
      imgSrc: '/assets/body.png',
      title: t('types.trajtimeTrupi'),
      description: t('types.trajtimeTrupiDesc'),
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{t('header.title')}</title>
      </Helmet>

             {/* Hero Section */}
      <header
        className="hero-container header-home" id="intro"
        style={{ backgroundPositionY: `${scrollPosition * 0}px` }} // Apply parallax effect
      >
        <div className="hero-content">
          {/* Text Section */}
          <h1 className="hero-home hero-title">{t('header.title')}</h1>
          {/* Contact Button */}
          <a href="tel:+355676869685" className="btn btn-primary">{t('header.reserve')}</a>
        </div>

          <div className='partner-section padding-bottom-small'>
            <h3 className="partners-title">{t('partners.title')}</h3>
            <div className="trusted-partners">
              <a href="https://www.medicatechnics.com/">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/medica.png`}
                  alt="medica"
                />
              </a>
              <a href="https://muster-dikson.com/en/categoria/products-dikson">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/muster.png`}
                  alt="muster"
                />
              </a>
              <a href="https://www.globuscorporation.com/en/">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/globus.png`}
                  alt="globus"
                />
              </a>
              <a href="https://www.compex.com/en/">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/compex.png`}
                  alt="compex"
                />
              </a>
            </div>
          </div>
        </header>

      <div className="clinic-section container">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/assets/why us.png`} alt={t('clinic.imageAlt')} />
        </div>
        <div className="text-container">
          <h1>{t('clinic.whyTitle')}</h1>
          <p>{t('clinic.description')}</p>
          <a href='/about' className="btn btn-primary btn-about">{t('clinic.aboutUs')}</a>
        </div>
      </div>
      <section className="container types-section">
        <h1 className='center-title'>{t('services-home.title')}</h1>
        <p className='center-p'>{t('services-home.description')}</p>
        <Swiper
          spaceBetween={100}
          slidesPerView={1}
          autoplay={{
            delay: 3000, // Slide every 3 seconds
            disableOnInteraction: false, // Disable autoplay when user interacts
            pauseOnMouseEnter: true, // Pause autoplay on hover
          }}
          modules={[Pagination, Navigation, EffectCoverflow, Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 100,
            },
          }}
        >
          {types.map((type, index) => (
            <SwiperSlide key={index}>
              <div className="types-slide">
                <img src={type.imgSrc} alt={type.title} />
                <div>
                  <h4 className='types-title'>{type.title}</h4>
                  <p>{type.description}</p>
                  <a href={`/services`} className="btn btn-primary btn-types">{t('services-home.readMore')}</a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section className="container services-overview-section">
        <div className="services-overview-title">
          <h1>{t('certifications.title')}</h1>
          <p>{t('certifications.description')}</p>
        </div>
        <div className="services-overview-blobs">
          <a href="offer" className="service-overview-blob-art">
            <img src="/assets/physiotherapy icon.png" alt={t('icons.physiotherapy')} />
          </a>
          <a href="offer" className="service-overview-blob-art">
            <img src="/assets/laser gun.png" alt={t('icons.laserGun')} />
          </a>
          <a href="offer" className="service-overview-blob-art">
            <img src="/assets/ropes.png" alt={t('icons.ropes')} />
          </a>
          <a href="offer" className="service-overview-blob-art">
            <img src="/assets/kawaii.png" alt={t('icons.kawaii')} />
          </a>
        </div>
        <div className="services-button">
          <a href="/offer" className="btn btn-background">{t('certifications.viewCertifications')}</a>
        </div>

      </section>
    </div>
  );
};

export default Home;
