import React, {useState, useEffect} from 'react';
import '../css/about.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();
    const [scrollPosition, setScrollPosition] = useState(0);

    // Track scroll position to apply parallax effect
    useEffect(() => {
      const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
        <div>
            <Helmet>
                <title>{t('about.title')} - Meliora Medical</title>
            </Helmet>
      {/* Hero Section */}
      <div
        className="hero-container header-about"
        style={{ backgroundPositionY: `${scrollPosition * 0}px` }} // Apply parallax effect
      >
        <div className="hero-content">
          {/* Text Section */}
          <h1 className="hero-title">{t('about.title')}</h1>
          {/* Contact Button */}
          <a href="tel:+355676869685" className="btn btn-primary">{t('Telefono')}</a>
        </div>
      </div>

            {/* Section 1 - Values */}
            <section className="container about-section about-intro">
                <h1>{t('about.clinicTitle')}</h1>
                <p>{t('about.clinicDescription')}</p>
            </section>

            {/* Section 2 - Our Mission */}
            <section className="container about-section about-mission">
                <div className="about-text">
                    <h2>{t('about.missionTitle')}</h2>
                    <p>{t('about.missionDescription')}</p>
                </div>
                <div className="about-image">
                    <img src={`${process.env.PUBLIC_URL}/assets/mission.png`} alt={t('about.missionImageAlt')} />
                </div>
            </section>

            {/* Section 3 - Our Vision */}
            <section className="container about-section about-vision">
                <div className="about-text">
                    <h2>{t('about.visionTitle')}</h2>
                    <p>{t('about.visionDescription')}</p>
                </div>
                <div className="about-image">
                    <img src={`${process.env.PUBLIC_URL}/assets/vision.png`} alt={t('about.visionImageAlt')} />
                </div>
            </section>
        </div>
    );
};

export default About;
